import React, { SyntheticEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
    saveButton: {
        backgroundColor: 'white',
        textTransform: 'none',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        fontSize: '1rem',
        borderRadius: 100,
        '&:disabled': {
            backgroundColor: '#e0e0e0!important',
            color: '#a6a6a6'
        }
    }
}));

interface SaveButtonProps {
    onClick?: (event: SyntheticEvent) => void;
    disabled: boolean;
    buttonText: string;
}

export const SaveButton: React.FunctionComponent<SaveButtonProps> = ({ onClick, disabled, buttonText }: SaveButtonProps) => {
    const classes = useStyles();

    return (
        <Button variant="contained"
            disableElevation
            data-testid="save-button"
            disabled={disabled}
            endIcon={<GetApp />}
            onClick={onClick}
            className={classes.saveButton}
        >
            {buttonText}
        </Button>
    );
};