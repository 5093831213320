import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

interface PageDescriptionProps {
  children?: React.ReactNode;
}

export const PageDescription: React.FunctionComponent<PageDescriptionProps> = (({ children }: PageDescriptionProps) => {
  const classes = makeStyles(() => ({
    root: {
      color: 'white',
      margin: "24px 0px",
    }
  }))();

  return (
    <Typography variant='h6' className={classes.root}>
      {children}
    </Typography>
  )
});
