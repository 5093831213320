import { Channel } from "./ChannelRepository";

export const ChannelManifest: Channel[] = [
  {
    id: 'covid-19-vaccine-story',
    title: 'COVID-19 Vaccine Story',
    image: 'images/bare/thumb.png',
    contentItems: [ 
      {
        id: 'ian',   
        title: 'Ian\'s Story',
        image: 'bare/ian.png',
        videos: [
          'Ian.webm',
          'Ian.mp4',
        ],
      },
      {
        id: 'fiona',   
        title: 'Fiona\'s Story',
        image: 'bare/fiona.png',
        videos: [
          'Fiona.webm',
          'Fiona.mp4',
        ],
      },
      {
        id: 'aaleyah',   
        title: 'Aaleyah and Matthew\'s Story',
        image: 'bare/aaleyah.png',
        videos: [
          'Aaleyah.webm',
          'Aaleyah.mp4',
        ],
      },
      {
        id: 'uncle-dale story',   
        title: 'Uncle Dale',
        image: 'bare/uncle-dale.png',
        videos: [
          'Uncle_Dale.webm',
          'Uncle_Dale.mp4',
        ],
      },
      {
        id: 'robbie',   
        title: 'Robbie\'s Story',
        image: 'bare/robbie.png',
        videos: [
          'Robbie.webm',
          'Robbie.mp4',
        ],
      },
      {
        id: 'lucy',   
        title: 'Lucy\'s Story',
        image: 'bare/lucy.png',
        videos: [
          'Lucy.webm',
          'Lucy.mp4',
        ],
      },
      {
        id: 'dr-mark',   
        title: 'Dr Mark Wenitong',
        image: 'bare/dr-mark.png',
        videos: [
          'Dr_Mark.webm',
          'Dr_Mark.mp4',
        ],
      },
      {
        id: 'aunty-eslyn',   
        title: 'Aunty Eslyn',
        image: 'bare/aunty-eslyn.png',
        videos: [
          'Aunty_Eslyn.webm',
          'Aunty_Eslyn.mp4',
        ],
      },
      {
        id: 'aunty-val',   
        title: 'Aunty Val and Lelani',
        image: 'bare/aunty-val.png',
        videos: [
          'Aunty_Val.webm',
          'Aaunty_Val.mp4',
        ],
      },
      {
        id: 'eastern-arrente-family',   
        title: 'Eastern Arrente Family',
        image: 'bare/eastern-arrente-family.png',
        videos: [
          'Eastern_Arrente_Family.webm',
          'Eastern_Arrente_Family.mp4',
        ],
      },
      {
        id: 'marcia',   
        title: 'Prof Marcia Langton',
        image: 'bare/marcia.png',
        videos: [
          'Marcia.webm',
          'Marcia.mp4',
        ],
      },
      {
        id: 'tom',   
        title: 'Prof Tom Calma',
        image: 'bare/tom.png',
        videos: [
          'Tom.webm',
          'Tom.mp4',
        ],
      },
    ],
  },
  {
    id: 'coronavirus-info',
    title: 'Coronavirus Info',
    image: 'thumb.jpg',
    contentItems: [
      {
        id: 'covid-19-survivor',
        title: 'COVID 19 Survivor',
        image: 'bare/covid-19-survivor.jpg',
        videos: [
          'Covid-19_Survivor.webm',
          'Covid-19_Survivor.mp4',
        ],
      },
      {
        id: 'dr-joel-wenitong',
        title: 'Dr Joel Wenitong',
        image: 'bare/dr-joel-wenitong.jpg',
        videos: [
          'Dr_Joel_Wenitong.webm',
          'Dr_Joel_Wenitong.mp4',
        ],
      },
      {
        id: 'fiona-jose',
        title: 'Fiona Jose',
        image: 'bare/fiona-jose.jpg',
        videos: [
          'Fiona_Jose.webm',
          'Fiona_Jose.mp4',
        ],
      },
      {
        id: 'sean-choolburra',
        title: 'Sean Choolburra',
        image: 'bare/sean-choolburra.jpg',
        videos: [
          'Sean_Choolburra.webm',
          'Sean_Choolburra.mp4',
        ],
      },
      {
        id: 'mi-keasha-masella',
        title: 'Mi-keasha Masella',
        image: 'bare/mi-keasha-masella.jpg',
        videos: [
          'Mi-keasha_Masella.webm',
          'Mi-keasha_Masella.mp4',
        ],
      },
      {
        id: 'social-distancing',
        title: 'Social Distancing',
        image: 'bare/social-distancing.jpg',
        videos: [
          'Social_Distancing.webm',
          'Social_Distancing.mp4',
        ],
      },
      {
        id: 'staying-connected',
        title: 'Staying Connected',
        image: 'bare/staying-connected.jpg',
        videos: [
          'Staying_Connected.webm',
          'Staying_Connected.mp4',
        ],
      },
      {
        id: 'about-testing',
        title: 'About Testing',
        image: 'bare/about-testing.jpg',
        videos: [
          'About_Testing.webm',
          'About_Testing.mp4',
        ],
      },
      {
        id: 'covid-19-and-cancer',
        title: 'COVID-19 and Cancer',
        image: 'bare/covid-19-and-cancer.jpg',
        videos: [
          'COVID19_And_Cancer.webm',
          'COVID19_And_Cancer.mp4',
        ],
      },
      {
        id: 'deadly-hand-washing',
        title: 'Deadly Hand Washing',
        image: 'bare/deadly-hand-washing.jpg',
        videos: [
          'Deadly_Hand_Washing.webm',
          'Deadly_Hand_Washing.mp4',
        ],
      },
      {
        id: 'prevent-coronavirus',
        title: 'Prevent Coronavirus',
        image: 'bare/prevent-coronavirus.jpg',
        videos: [
          'Prevent_Coronavirus.webm',
          'Prevent_Coronavirus.mp4',
        ],
      },
      {
        id: 'raylene-from-awabakal',
        title: 'Raylene from Awabakal',
        image: 'bare/raylene-from-awabakal.jpg',
        videos: [
          'Raylene_From_Awabakal.webm',
          'Raylene_From_Awabakal.mp4',
        ],
      },
    ],
  },
  {
    id: 'find-out-about-the-ndis',
    title: 'Find Out About The NDIS',
    image: 'images/bare/thumb.png',
    contentItems: [ 
      {
        id: 'introduction', 
        title: 'Introduction',
        image: 'bare/introduction.png',
        videos: [
          'Introduction.webm',
          'Introduction.mp4',
        ],
      },
      {
        id: 'part1-access', 
        title: 'Access',
        image: 'bare/part1-access.png',
        videos: [
          'Part1_Access.webm',
          'Part1_Access.mp4',
        ],
      },
      {
        id: 'part2-planning',
        title: 'Planning',
        image: 'bare/part2-planning.png',
        videos: [
        'Part2_Planning.webm',
        'Part2_Planning.mp4',
        ],
      },
      {
        id: 'part3-review',
        title: 'Review',
        image: 'bare/part3-review.png',
        videos: [
          'Part3_Review.webm',
          'Part3_Review.mp4',
        ],
      },
      {
        id: 'ndis-provider-finder',
        title: 'Provider Finder Tool',
        image: 'bare/ndis-provider-finder.png',
        videos: [
          'Ndis_Provider_Finder.webm',
          'Ndis_Provider_Finder.mp4',
        ],
      },
      {
        id: 'care-plans',
        title: 'Care Plans',
        image: 'bare/care-plans.png',
        videos: [
          'Care_Plans.webm',
          'Care_Plans.mp4',
        ],
      },
      {
        id: 'caring-routines',
        title: 'Caring Routines',
        image: 'bare/caring-routines.png',
        videos: [
          'Caring_Routines.webm',
          'Caring_Routines.mp4',
        ],
      },
      {
        id: 'time-to-listen,-time-to-change',
        title: 'Time To Listen, Time To Change',
        image: 'bare/time-to-listen.png',
        videos: [
          'Time_To_Listen,_Time_To_Change.webm',
          'Time_To_Listen,_Time_To_Change.mp4',
        ],
      },
      {
        id: 'working-as-a-provider',
        title: 'Working As A Provider',
        image: 'bare/working-as-a-provider.png',
        videos: [
          'Working_As_A_Provider.webm',
          'Working_As_A_Provider.mp4',
        ],
      },
    ],
  }, 
  {
    id: 'rheumatic-heart-disease',
    title: 'Rheumatic Heart Disease',
    image: 'thumb.jpg',
    contentItems: [ 
      {
        id: 'preventable',   
        title: '100% Preventable',
        image: 'bare/preventable.png',
        videos: [
          'Preventable.webm',
          'Preventable.mp4',
        ],
      },
      {
        id: 'good-hygiene',   
        title: 'Good Hygiene',
        image: 'bare/good-hygiene.png',
        videos: [
          'Good_Hygiene.webm',
          'Good_Hygiene.mp4',
        ],
      },
      {
        id: 'protect-our-youth',   
        title: 'Protect Our Youth',
        image: 'bare/protect-our-youth.png',
        videos: [
          'Protect_Our_Youth.webm',
          'Protect_Our_Youth.mp4',
        ],
      },
      {
        id: 'deb-message',   
        title: 'Deb\'s Message',
        image: 'bare/deb-message.png',
        videos: [
          'Deb_Message.webm',
          'Deb_Message.mp4',
        ],
      },
      {
        id: 'pats-story',   
        title: 'Pat\'s Story',
        image: 'bare/pats-story.png',
        videos: [
          'Pats_Story.webm',
          'Pats_Story.mp4',
        ],
      },
    ],
  },
  {
    id: 'yirara tv',
    title: 'Yirara TV',
    image: 'images/bare/thumb.png',
    contentItems: [ 
      {
        id: 'ep9-t3', 
        title: 'Ep 9, Term 3 2021',
        image: 'bare/ep9-t3.png',
        videos: [
          'Ep9_T3.webm',
          'Ep9_T3.mp4',
        ],
      },
      {
        id: 'ep8-t3', 
        title: 'Ep 8, Term 3 2021',
        image: 'bare/ep8-t3.png',
        videos: [
          'Ep8_T3.webm',
          'Ep8_T3.mp4',
        ],
      },
      {
        id: 'ep7-t3', 
        title: 'Ep 7, Term 3 2021',
        image: 'bare/ep7-t3.png',
        videos: [
          'Ep7_T3.webm',
          'Ep7_T3.mp4',
        ],
      },
      {
        id: 'ep6-t3', 
        title: 'Ep 6, Term 3 2021',
        image: 'bare/ep6-t3.png',
        videos: [
          'Ep6_T3.webm',
          'Ep6_T3.mp4',
        ],
      },
      {
        id: 'ep5-t3', 
        title: 'Ep 5, Term 3 2021',
        image: 'bare/ep5-t3.png',
        videos: [
          'Ep5_T3.webm',
          'Ep5_T3.mp4',
        ],
      },
      {
        id: 'ep4-t3', 
        title: 'Ep 4, Term 3 2021',
        image: 'bare/ep4-t3.png',
        videos: [
          'Ep4_T3.webm',
          'Ep4_T3.mp4',
        ],
      },
      {
        id: 'ep3-t3', 
        title: 'Ep 3, Term 3 2021',
        image: 'bare/ep3-t3.png',
        videos: [
          'Ep3_T3.webm',
          'Ep3_T3.mp4',
        ],
      },
      {
        id: 'ep2-t3', 
        title: 'Ep 2, Term 3 2021',
        image: 'bare/ep2-t3.png',
        videos: [
          'Ep2_T3.webm',
          'Ep2_T3.mp4',
        ],
      },
      {
        id: 'ep1-t3', 
        title: 'Ep 1, Term 3 2021',
        image: 'bare/ep1-t3.png',
        videos: [
          'Ep1_T3.webm',
          'Ep1_T3.mp4',
        ],
      },
      {
        id: 'ep9-t2', 
        title: 'Ep 9, Term 2 2021',
        image: 'bare/ep9-t2.png',
        videos: [
          'Ep9_T2.webm',
          'Ep9_T2.mp4',
        ],
      },
      {
        id: 'ep8-t2', 
        title: 'Ep 8, Term 2 2021',
        image: 'bare/ep8-t2.png',
        videos: [
          'Ep8_T2.webm',
          'Ep8_T2.mp4',
        ],
      },
      {
        id: 'ep7-t2', 
        title: 'Ep 7, Term 2 2021',
        image: 'bare/ep7-t2.png',
        videos: [
          'Ep7_T2.webm',
          'Ep7_T2.mp4',
        ],
      },
    ],
  },
  {
    id: 'her-heart',
    title: "Her Heart",
    image: 'images/bare/thumb.png',
    contentItems: [
      {
        id: 'what-is-heart-disease',
        title: 'What is heart disease?',
        image: 'bare/what-is-heart-disease.png',
        videos: [
          'animation1.webm',
          'animation1.mp4',
        ],
      },
      {
        id: 'how-to-prevent-it',
        title: 'How to prevent it?',
        image: 'bare/how-to-prevent-it.png',
        videos: [
          'animation2.webm',
          'animation2.mp4',
        ],
      },
      {
        id: 'living-with-heart-disease',
        title: 'Living with heart disease',
        image: 'bare/living-with-heart-disease.png',
        videos: [
          'animation3.webm',
          'animation3.mp4',
        ],
      },
      // {
      //   id: 'where-to-get-help',
      //   title: 'Where to get help',
      //   image: 'where-to-get-help.png',
      //   videos: [ // TODO: this is an image and not a video
      //     // '',
      //     // '',
      //   ],
      // },
      {
        id: 'nolas-story',
        title: 'Nola\'s story',
        image: 'bare/nolas-story.png',
        contentItems: [
          {
            id: 'nola-risk-factors',
            title: 'Risk Factors',
            image: 'bare/nola-risk-factors.png',
            videos: [
              'nola-riskfactors.webm',
              'nola-riskfactors.mp4'
            ]
          },
          {
            id: 'nola-symptoms',
            title: 'Symptoms',
            image: 'bare/nola-symptoms.png',
            videos: [
              'nola-symptoms.webm',
              'nola-symptoms.mp4'
            ]
          },
          {
            id: 'nola-lifestyle-changes',
            title: 'Lifestyle Changes',
            image: 'bare/nola-lifestyle-changes.png',
            videos: [
              'nola-lifestyle-changes.webm',
              'nola-lifestyle-changes.mp4'
            ]
          }
        ],
      },
      {
        id: 'annettes-story',
        title: 'Annette\'s story',
        image: 'bare/annettes-story.png',
        contentItems: [
          {
            id: 'annette-risk-factors',
            title: 'Risk Factors',
            image: 'bare/annette-risk-factors.png',
            videos: [
              'annette-riskfactors.webm',
              'annette-riskfactors.mp4'
            ]
          },
          {
            id: 'annette-symptoms',
            title: 'Symptoms',
            image: 'bare/annette-symptoms.png',
            videos: [
              'annette-symptoms.webm',
              'annette-symptoms.mp4'
            ]
          },
          {
            id: 'annette-lifestyle-changes',
            title: 'Lifestyle Changes',
            image: 'bare/annette-lifestyle-changes.png',
            videos: [
              'annette-lifestyle-changes.webm',
              'annette-lifestyle-changes.mp4'
            ]
          }
        ],
      },
      {
        id: 'janelles-story',
        title: 'Janelle\'s story',
        image: 'bare/janelles-story.png',
        contentItems: [
          {
            id: 'janelle-treatment',
            title: 'Risk Factors',
            image: 'bare/janelles-treatment.png',
            videos: [
              'janelle-treatment.webm',
              'janelle-treatment.mp4'
            ]
          },
          {
            id: 'janelle-lifestyle-changes',
            title: 'Lifestyle Changes',
            image: 'bare/janelle-lifestyle-changes.png',
            videos: [
              'janelle-lifestylechanges.webm',
              'janelle-lifestylechanges.mp4'
            ]
          }
        ],
      },
      {
        id: 'ursulas-story',
        title: 'Ursula\'s story',
        image: 'bare/ursulas-story.png',
        contentItems: [
          {
            id: 'ursula-risk-factors',
            title: 'Risk Factors',
            image: 'bare/ursula-risk-factors.png',
            videos: [
              'ursula-riskfactors.webm',
              'ursula-riskfactors.mp4'
            ]
          },
          {
            id: 'ursula-symptoms',
            title: 'Symptoms',
            image: 'bare/ursula-symptoms.png',
            videos: [
              'ursula-symptoms.webm',
              'ursula-symptoms.mp4'
            ]
          },
          {
            id: 'ursula-lifestyle-changes',
            title: 'Lifestyle Changes',
            image: 'bare/ursula-lifestyle-changes.png',
            videos: [
              'ursula-lifestylechanges.webm',
              'ursula-lifestylechanges.mp4'
            ]
          }
        ],
      },
    ],
  },
  {
    id: 'young-deadly-free',
    title: "Young Deadly Free",
    image: 'thumb.png',
    contentItems: [
      {
        id: 'snippets-of-gold',
        title: 'Snippets of Gold',
        image: 'bare/snippets-of-gold.jpg',
        videos: [
          'Snippets_Of_Gold.webm',
          'Snippets_Of_Gold.mp4',
        ],
      },
      {
        id: 'u-and-me-can-stop-hiv',
        title: 'U and Me can stop HIV',
        image: 'bare/u-and-me-can-stop-hiv.jpg',
        videos: [
          'U_And_Me_Can_Stop_HIV.webm',
          'U_And_Me_Can_Stop_HIV.mp4',
        ],
      },
      {
        id: 'get-tested-for-stis',
        title: 'Get tested for STIs',
        image: 'bare/get-tested-for-stis.jpg',
        videos: [
          'Get_Tested_For_STIs.webm',
          'Get_Tested_For_STIs.mp4',
        ],
      },
      {
        id: 'you-can-ask-that-episode1',
        title: 'You can ask that - Episode 1',
        image: 'bare/you-can-ask-that-episode1.jpg',
        videos: [
          'You_Can_Ask_That_Episode1.webm',
          'You_Can_Ask_That_Episode1.mp4',
        ],
      },
      {
        id: 'youth-yarn-about',
        title: 'Youth Yarn about',
        image: 'bare/youth-yarn-about.jpg',
        videos: [
          'Youth_Yarn_About.webm',
          'Youth_Yarn_About.mp4',
        ],
      },
      {
        id: 'antenatal-care',
        title: 'Antenatal Care',
        image: 'bare/antenatal-care.jpg',
        videos: [
          'Antenatal_Care.webm',
          'Antenatal_Care.mp4',
        ],
      },
      {
        id: 'sti',
        title: 'What are STIs?',
        image: 'bare/sti.jpg',
        videos: [
          'STIs.webm',
          'STIs.mp4',
        ],
      },
      {
        id: 'hiv',
        title: 'HIV',
        image: 'bare/hiv.jpg',
        videos: [
          'HIV.webm',
          'HIV.mp4',
        ],
      },
    ],
  },
  {
    id: 'kids-matter',
    title: 'Kids Matter',
    image: 'thumb.jpg',
    contentItems: [
      {
        id: 'digby',
        title: 'Digby',
        image: 'bare/digby.jpg',
        videos: [
          'Digby.webm',
          'Digby.mp4',
        ],
      },
      {
        id: 'my-fathers-words',
        title: "My Father's Words",
        image: 'bare/my-fathers-words.jpg',
        videos: [
          'My_Fathers_Words.webm',
          'My_Fathers_Words.mp4',
        ],
      },
      {
        id: 'my-grandfather',
        title: 'My Grandfather',
        image: 'bare/my-grandfather.jpg',
        videos: [
          'My_Grandfather.webm',
          'My_Grandfather.mp4',
        ],
      },
      {
        id: 'nannas-painting',
        title: "Nanna's Painting",
        image: 'bare/nannas-painting.jpg',
        videos: [
          'Nannas_Painting.webm',
          'Nannas_Painting.mp4',
        ],
      },
      {
        id: 'run',
        title: 'Run',
        image: 'bare/run.jpg',
        videos: [
          'Run.webm',
          'Run.mp4',
        ],
      },
      {
        id: 'snapshots',
        title: 'Snapshots',
        image: 'bare/snapshots.jpg',
        videos: [
          'SnapShots.webm',
          'SnapShots.mp4',
        ],
      },
      {
        id: 'spirit',
        title: 'Spirit',
        image: 'bare/spirit.jpg',
        videos: [
          'Spirit.webm',
          'Spirit.mp4',
        ],
      },
      {
        id: 'thank-you',
        title: 'Thank You',
        image: 'bare/thank-you.jpg',
        videos: [
          'Thank_You.webm',
          'Thank_You.mp4',
        ],
      },
      {
        id: 'the-artist',
        title: 'The Artist',
        image: 'bare/the-artist.jpg',
        videos: [
          'The_Artist.webm',
          'The_Artist.mp4',
        ],
      },
      {
        id: 'the-job-interview',
        title: 'The Job Interview',
        image: 'bare/the-job-interview.jpg',
        videos: [
          'The_Job_Interview.webm',
          'The_Job_Interview.mp4',
        ],
      },
      {
        id: 'walk-of-life',
        title: 'Walk of Life',
        image: 'bare/walk-of-life.jpg',
        videos: [
          'Walk_of_Life.webm',
          'Walk_of_Life.mp4',
        ],
      },
      {
        id: 'window-shopping',
        title: 'Window Shopping',
        image: 'bare/window-shopping.jpg',
        videos: [
          'Window_Shopping.webm',
          'Window_Shopping.mp4',
        ],
      },
    ]
  },
  {
    id: 'be-deadly-online',
    title: 'Be Deadly Online',
    image: 'thumb.jpg',
    contentItems: [
      {
        id: 'respect-circle',
        title: 'Respect Circle',
        image: 'bare/respect-circle.jpg',
        videos: [
          'Respect_Circle.webm',
          'Respect_Circle.mp4',
        ],
      },
      {
        id: 'john-paul-janke-esafety',
        title: 'John Paul Janke: eSafety',
        image: 'bare/john-paul-janke-esafety.jpg',
        videos: [
          'John_Paul_Janke_eSafety.webm',
          'John_Paul_Janke_eSafety.mp4',
        ],
      },
      {
        id: 'cairns-keep-your-mob-safe-online',
        title: 'Cairns: Keep your mob safe online',
        image: 'bare/cairns-keep-your-mob-safe-online.jpg',
        videos: [
          'Cairns_Keep_Our_Mob_Safe_Online.webm',
          'Cairns_Keep_Our_Mob_Safe_Online.mp4',
        ],
      },
      {
        id: 'stars-foundation-esafety',
        title: 'Stars Foundation: eSafety',
        image: 'bare/stars-foundation-esafety.jpg',
        videos: [
          'Stars_Foundation_eSafety.webm',
          'Stars_Foundation_eSafety.mp4',
        ],
      },
      {
        id: 'b2m-cyberbullying',
        title: 'B2M: Cyberbullying',
        image: 'bare/b2m-cyberbullying.jpg',
        videos: [
          'B2M_Cyberbullying.webm',
          'B2M_Cyberbullying.mp4',
        ],
      },
      {
        id: 'cyber-bullying',
        title: 'Cyberbullying',
        image: 'bare/cyber-bullying-vid.jpg',
        videos: [
          'CyberbullyingVid.webm',
          'CyberbullyingVid.mp4',
        ],
      },
      {
        id: 'digital-footprint',
        title: 'Digital Footprint',
        image: 'bare/digital-footprint-vid.jpg',
        videos: [
          'DigitalFootprintVid.webm',
          'DigitalFootprintVid.mp4',
        ],
      },
      {
        id: 'thats-not-team-spirit',
        title: "That's Not Team Spirit",
        image: 'bare/thats-not-team-spirit-vid.jpg',
        videos: [
          'ThatsNotTeamSpiritVid.webm',
          'ThatsNotTeamSpiritVid.mp4',
        ],
      },
      {
        id: 'little-things',
        title: 'Little Things',
        image: 'bare/little-things-vid.jpg',
        videos: [
          'LittleThingsVid.webm',
          'LittleThingsVid.mp4',
        ],
      },
      {
        id: 'dumb-stuff',
        title: 'Dumb Stuff',
        image: 'bare/dumb-stuff-vid.png', // TODO: Should be dumb-stuff-vid.jpg
        videos: [
          'DumbStuffVid.webm',
          'DumbStuffVid.mp4',
        ],
      }
    ]
  },
  {
    id: 'money-channel',
    title: 'Money Channel',
    image: 'thumb.jpg',
    contentItems: [
      {
        id: 'first-nations-foundation',
        title: 'First Nations Foundation',
        image: 'bare/first-nations-foundation.jpg',
        description: 'The First Nations Foundation explaining their mission to empower First Australians with financial literacy training to help them achieve financial freedom.',
        videos: [
          'First_Nations_Foundation.webm',
          'First_Nations_Foundation.mp4',
        ],
      },
      {
        id: 'my-money-dream',
        title: 'My Money Dream',
        image: 'bare/my-money-dream.jpg', // TODO: Still has embedded title,
        description: 'The First Nations Foundation explains their digital program for the financial services sector, supporting financial literacy for their Indigenous employees, customers and clients.',
        videos: [
          'My_Money_Dream.webm',
          'My_Money_Dream.mp4',
        ],
      },
      {
        id: 'dealing-with-family-pressure',
        title: 'Dealing with Family Pressure',
        image: 'bare/dealing-with-family-pressure.jpg',
        description: 'Knowing when to save your money for future goals rather than spending it all on short term requests. A short animation from ASIC.',
        videos: [
          'Dealing_With_Family_Pressure.webm',
          'Dealing_With_Family_Pressure.mp4',
        ],
      },
      {
        id: 'direct-debits',
        title: 'Direct Debits',
        image: 'bare/direct-debits.jpg',
        description: 'The ACCC explains why you need to keep track of your subscriptions and contracts so that you know when money is automatically deducted from your account.',
        videos: [
          'Direct_Debits.webm',
          'Direct_Debits.mp4',
        ],
      },
      {
        id: 'how-to-transfer-money',
        title: 'How to transfer money',
        image: 'bare/how-to-transfer-money.jpg',
        description: 'Indigimob brings you step by step instructions in Warlpiri on how to use your computer for Online Banking to transfer money from your Bank to someone else.',
        videos: [
          'How_To_Transfer_Money.webm',
          'How_To_Transfer_Money.mp4',
        ],
      },
      {
        id: 'sorting-out-your-money-problems',
        title: 'Sorting out your money problems',
        image: 'bare/sorting-out-your-money-problems.jpg',
        description: 'The benefits of financial counselling to help you get on top of money problems. There\'s no shame in getting a money checkup, an important message from ASIC.',
        videos: [
          'Sorting_Out_Your_Money_Problems.webm',
          'Sorting_Out_Your_Money_Problems.mp4',
        ],
      },
      {
        id: 'buying_a_car',
        title: 'Buying a Car',
        image: 'bare/buying_a_car.jpg',
        description: 'Tips from ASIC on what to watch out for when buying a car so that you get the most out of your money.',
        videos: [
          'Buying_A_Car.webm',
          'Buying_A_Car.mp4',
        ],
      },
      {
        id: 'book_up',
        title: 'How to manage your store account or tab',
        image: 'bare/book_up.jpg',
        description: 'Handy hints from ASIC on how to keep track of your store account so you don\'t spend more than you want to.',
        videos: [
          'Book_Up.webm',
          'Book_Up.mp4',
        ],
      },
      {
        id: 'renting_things_for_your_home',
        title: 'Renting things for your home',
        image: 'bare/renting_things_for_your_home.jpg',
        description: 'ASIC shows how renting can cost more than an item is worth. They also advise on some alternatives to renting',
        videos: [
          'Renting_Things_For_Your_Home.webm',
          'Renting_Things_For_Your_Home.mp4',
        ],
      },
      {
        id: 'credit_card_story',
        title: 'Credit Card Story',
        image: 'bare/credit_card_story.jpg',
        description: 'An animation from italk studios that has handy hints for the best way to manage your credit card and avoid interest costs.',
        videos: [
          'Credit_Card_Story.webm',
          'Credit_Card_Story.mp4',
        ],
      },
      {
        id: 'saving',
        title: 'Saving',
        image: 'bare/saving.jpg',
        description: 'italk tells the story of how a woman saved $40 each month in a Christmas savings account and ended up with $480 to share with her family for Christmas',
        videos: [
          'Saving.webm',
          'Saving.mp4',
        ],
      },
      {
        id: 'loan_shark',
        title: 'Loan Shark',
        image: 'bare/loan_shark.jpg',
        description: 'Loan sharks are people who lend money without any agreements on how much they will take back. This italk story advises everyone to get advice before borrowing money',
        videos: [
          'Loan_Shark.webm',
          'Loan_Shark.mp4',
        ],
      },
    ]
  },
  {
    id: 'deadly-designers',
    title: 'Deadly Designers',
    image: 'thumb.jpg',
    contentItems: [
      {
        id: 'artist-and-art-centre-agreement',
        title: 'Artist & Art Centre Agreement',
        image: 'bare/artist-and-art-centre-agreement.jpg',
        videos: [
          'Artist_And_Art_Centre_Agreement.webm',
          'Artist_And_Art_Centre_Agreement.mp4',
        ],
      },
      {
        id: 'protecting-your-artwork',
        title: 'Protecting Your Artwork',
        image: 'bare/protecting-your-artwork.jpg',
        videos: [
          'Protecting_Your_Artwork.webm',
          'Protecting_Your_Artwork.mp4',
        ],
      },
      {
        id: 'contract-for-artists',
        title: 'Contracts for Artists',
        image: 'bare/contract-for-artists.jpg',
        videos: [
          'Contracts.webm',
          'Contracts.mp4',
        ],
      },
      {
        id: 'copyright-for-artists',
        title: 'Copyright for Artists',
        image: 'bare/copyright-for-artists.jpg',
        videos: [
          'Copyright.webm',
          'Copyright.mp4',
        ],
      },
      {
        id: 'intellectual-property-for-artists',
        title: 'Intellectual Property for Artists',
        image: 'bare/intellectual-property-for-artists.jpg',
        videos: [
          'ICIP.webm',
          'ICIP.mp4',
        ],
      },
      {
        id: 'resale-royalty-for-art',
        title: 'Resale Royalty for your Art',
        image: 'bare/resale-royalty-for-art.jpg',
        videos: [
          'Resale_Royalty.webm',
          'Resale_Royalty.mp4',
        ],
      },
      {
        id: 'writing-will',
        title: 'Writing a Will',
        image: 'bare/writing-will.jpg',
        videos: [
          'Writing_A_Will.webm',
          'Writing_A_Will.mp4',
        ],
      },
      {
        id: 'jana_jaral',
        title: 'Jana Jaral - Respect',
        image: 'bare/jana_jaral.jpg',
        videos: [
          'Fashion_Festival_CIAF_2016.webm',
          'Fashion_Festival_CIAF_2016.mp4',
        ],
      },
      {
        id: 'birrimbi_durgu_bajal',
        title: 'Birrimbi Dulgu Bajal',
        image: 'bare/birrimbi_durgu_bajal.jpg',
        videos: [
          'Fashion_Festival_CIAF_2015.webm',
          'Fashion_Festival_CIAF_2015.mp4',
        ],
      },
      {
        id: 'ghost_net',
        title: 'Ghost Nets',
        image: 'bare/ghost_net.jpg',
        videos: [
          'Ghostnet.webm',
          'Ghostnet.mp4',
        ],
      },
    ]
  },
  {
    id: 'young-murri-leaders',
    title: 'Young Murri Leaders',
    image: 'thumb.jpg',
    contentItems: [
      {
        title: 'Culture',
        image: 'bare/culture.jpg',
        videos: [
          'Culture.webm',
          'Culture.mp4',
        ],
        id: 'culture',
      },
      {
        title: 'Goal Setting',
        image: 'bare/goal-setting.jpg',
        videos: [
          'Goal_Setting.webm',
          'Goal_Setting.mp4',
        ],
        id: 'goal-setting',
      },
      {
        title: 'Highlights Magnetic Island',
        image: 'bare/highlights-magnetic-island.jpg',
        videos: [
          'Highlights_Magnetic_Island.webm',
          'Highlights_Magnetic_Island.mp4',
        ],
        id: 'highlights-magnetic-island',
      },
      {
        title: 'Identity',
        image: 'bare/identity.jpg',
        videos: [
          'Identity.webm',
          'Identity.mp4',
        ],
        id: 'identity',
      },
      {
        title: 'Paluma Voices',
        image: 'bare/paluma-voices.jpg',
        videos: [
          'Paluma_Voices.webm',
          'Paluma_Voices.mp4',
        ],
        id: 'paluma-voices',
      },
      {
        title: 'Resilience',
        image: 'bare/resilience.jpg',
        videos: [
          'Resilience.webm',
          'Resilience.mp4',
        ],
        id: 'resilience',
      },
    ]
  },
  {
    id: 'ways',
    title: 'Wayz',
    image: 'thumb.jpg',
    contentItems: [
      {
        id: 'old-wayz-new-wayz',
        title: 'Old Wayz, New Wayz',
        image: 'bare/old-wayz-new-wayz.jpg',
        videos: [
        'Old_Wayz_New_Wayz.webm',
        'Old_Wayz_New_Wayz.mp4',
        ],
      },
      {
        id: 'positive-wayz',
        title: 'Positive Wayz',
        image: 'bare/positive-wayz.jpg',
        videos: [
        'Positive_Wayz.webm',
        'Positive_Wayz.mp4',
        ],
      },
      {
        id: 'sista-wayz',
        title: 'Sista Wayz',
        image: 'bare/sista-wayz.jpg',
        videos: [
        'Sista_Wayz.webm',
        'Sista_Wayz.mp4',
        ],
      },
      {
        id: 'wayz-forward',
        title: 'Wayz Forward',
        image: 'bare/wayz-forward.jpg',
        videos: [
        'Wayz_Forward.webm',
        'Wayz_Forward.mp4',
        ],
      },
      {
        id: 'which-way',
        title: 'Which Way?',
        image: 'bare/which-way.jpg',
        videos: [
        'Which_Way.webm',
        'Which_Way.mp4',
        ],
      },
    ]
  },    
]