import { Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#181818',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    opacity: '90%',
    width: '100vw',
    color: 'white',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

export const Disclaimer: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Grid container justify='center' spacing={1}>
        <Grid item xs={12} sm={8} md={12} lg={12} xl={12}>
          <Typography variant="body2">
            This website contains imagery and voices of Aboriginal and Torres Strait Islander people who may now be
            deceased.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
