import { Channel } from "../ChannelRepository";

export default class HitnetApiClient {
  private readonly url: string;

  constructor(url: string) {
    this.url = url;
  }

  getMyPlace(position: GeolocationPosition): Promise<Channel | null> {
    const endpointURL = new URL(`${this.url}/manifests/myplace`)
    if (position.coords) {
      endpointURL.searchParams.append('latitude', `${position.coords.latitude}`);
      endpointURL.searchParams.append('longitude', `${position.coords.longitude}`);
    }

    return fetch(endpointURL.href, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        switch (response.status) {
          case 204:
          case 400:
            return Promise.resolve(null);
          default:
            return response.json();
        }
      })
      .catch(error => {
        console.error(error);
        return Promise.resolve(null);
      });
  }
}
