import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
import EastWestIcon from './assets/EastWest.svg';
import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      margin: '0 auto',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
    cardMedia: {
      width: 180,
      height: 180,
      borderRadius: 12,
      margin: '0 auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    cardContent: {
      fontSize: '1.2em',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  };
});

export const InstructionsCard: React.FunctionComponent = () => {
  const classes = useStyles()
  return(
      <Card className={classes.card} data-testid='enable-location-access-instructions'>
        <Typography gutterBottom variant="h5" component="h2" align="center">
          Allow Location Access
        </Typography>

        <CardMedia component="img"
                   className={classes.cardMedia}
                   image={EastWestIcon}
                   title="My Place"
                   alt="My Place"
        />
        <CardContent>
          <Typography variant="body2" color="textPrimary" component="p" align="center"
                      className={classes.cardContent}>
            If you&apos;d like to see videos and content for your local region, we need
            permission to use your phone&apos;s location services.
          </Typography>

          <Typography variant="body2" color="textPrimary" component="p" align="center"
                      className={classes.cardContent}>
            You may need to adjust location settings in your browser.
          </Typography>
        </CardContent>
      </Card>
  )
}