import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import React, { SyntheticEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import offlineThumbnail from './assets/offlineThumbnail.png';
import { Channel, ContentItem, VideoItem } from './ChannelRepository';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: 12,
    },
    thumbnail: {
      backgroundColor: 'rgba(0,0,0,0)',
      objectFit: 'cover',

      '@media screen and (max-width:320px)': {
        width: 136,
        height: 136 - 50,
      },
      '@media screen and (min-width:321px) and (max-width:375px)': {
        width: 164,
        height: 164 - 50,
      },
      '@media screen and (min-width:376px)': {
        width: 182,
        height: 182 - 50,
      },
    },
    caption: {
      padding: theme.spacing(0.5, 2, 0.5, 1),
      height: 50,

      '@media screen and (max-width:320px)': {
        width: 136,
      },
      '@media screen and (min-width:321px) and (max-width:375px)': {
        width: 164,
      },
      '@media screen and (min-width:376px)': {
        width: 182,
      },
    },
  })
);

interface ContentItemCardProps {
  index: number;
  item: ContentItem;
  url: string;
  channel: Channel;
  parent?: Channel | undefined;
}

 export const ContentItemCard = ({ index, url, channel, item, parent }: ContentItemCardProps): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();

  const displayFallbackImage = (event: SyntheticEvent): void => {
    (event.target as HTMLImageElement).src = offlineThumbnail;
  }

  const isVideoItem = (item: ContentItem | VideoItem): item is VideoItem => {
    return (item as VideoItem).videos !== undefined;
  }

  const videoLocationDescriptor = {
    pathname: (isVideoItem(item) ? [url, 'videos', item.id] : [url, item.id]).join('/'),
    state: { redirectBackTo: location },
  };

  const encodedChannelTitle = encodeURIComponent((parent ? parent : channel).title);
  return (
    <Card className={ classes.card }>
      <CardActionArea component={ Link } to={ videoLocationDescriptor } data-testid={ `item-link-${ index }` }>
        <CardMedia component='img'
                   title={ item.title }
                   alt={ item.title }
                   data-testid={ `item-thumb-${ index }` }
                   src={ `https://content.hitnet.app/${ encodedChannelTitle }/assets/images/${ item.image }` }
                   className={ classes.thumbnail }
                   onError={ displayFallbackImage }
        />
        <CardContent className={ classes.caption }>
          <Typography variant='caption'>{ item.title }</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
