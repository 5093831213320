import { AppBar, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NavigateBeforeRounded } from '@material-ui/icons';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/Hitnet-Logo-Horizontal-White.svg';

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    backgroundColor: '#7C0612',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  backbutton: {
    color: '#ffffff',
    position: 'absolute',
    left: '1%',
    paddingRight: theme.spacing(3),
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    fontSize: '1.2em',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
      },
    },
  }
}));

export interface HeaderLocationState {
  redirectBackTo?: string;
}

export const Header: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();

  let backButtonPath = '/';

  const locationState = location.state as HeaderLocationState
  if (locationState && locationState.redirectBackTo) {
    backButtonPath = locationState.redirectBackTo;
  }

  return (
    <AppBar className={classes.appbar} position='relative'>
      {location.pathname !== '/' && (
        <Button className={classes.backbutton}
          component={Link}
          to={backButtonPath}
          startIcon={<NavigateBeforeRounded />}
        >
          Back
        </Button>
      )}

      <Link to='/' aria-label="Homepage">
        <Logo width='100px' data-testid='hitnet-logo' />
      </Link>
    </AppBar>
  );
};
