import { Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { SyntheticEvent } from 'react';
import { useHistory } from "react-router-dom";
import myPlaceIcon from "./assets/AusMapNavIcon.svg";
import offlineThumbnail from "./assets/offlineThumbnail.png";
import savedVideosIcon from "./assets/saved-videos.svg";
import { Channel, ChannelRepository, defaultChannelRepository } from './ChannelRepository';
import { Disclaimer } from "./Disclaimer";
import { ArrowIcon } from './sharedUiElements/ArrowIcon';
import { Subheading } from './sharedUiElements/Subheading';
import { WelcomeMessage } from "./WelcomeMessage";


const useStyles = makeStyles((theme: Theme) => {
  return {
    channelCard: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 16,
      paddingTop: theme.spacing(0.75),
      paddingRight: theme.spacing(1.5),
      paddingBottom: theme.spacing(0.75),
      paddingLeft: theme.spacing(1),
    },
    thumbnail: {
      width: 74,
      height: 53,
      borderRadius: 12,
      backgroundSize: 'cover',
    },
    content: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  };
});

interface ChannelCardProps {
  channel: Channel;
}

const ChannelCard = ({ channel }: ChannelCardProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const openChannelPage = (channelId: string): void => {
    history.push('/channels/' + channelId);
  };

  const displayFallbackImage = (event: SyntheticEvent): void => {
    (event.target as HTMLImageElement).src = offlineThumbnail;
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
      <CardActionArea onClick={(): void => openChannelPage(channel.id)}>
        <Card className={classes.channelCard}>
          <CardMedia component='img'
                     className={classes.thumbnail}
                     image={`https://content.hitnet.app/${encodeURIComponent(channel.path || channel.title)}/assets/${channel.image}`}
                     title={channel.title}
                     alt={channel.title}
                     id={`${channel.id}-thumbnail`}
                     onError={displayFallbackImage}
          />
          <CardContent className={classes.content}>
            <Typography variant="h3" noWrap>
              {channel.title}
            </Typography>
          </CardContent>
          <ArrowIcon />
        </Card>
      </CardActionArea>
    </Grid>
  );
};

interface HomePageProps {
  channelRepository?: ChannelRepository;
}

export const HomePage = ({ channelRepository = defaultChannelRepository }: HomePageProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const channels = channelRepository.getChannels();


  const navigateToSavedVideos = (): void => {
    history.push('/saved-videos');
  };
  const navigateToMyPlace = (): void => {
    history.push('/my-place');
  };

  return (
    <React.Fragment>
      <WelcomeMessage />

      <Subheading>Channels</Subheading>

      <Grid container spacing={2} justify='flex-start'>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CardActionArea onClick={navigateToSavedVideos}>
            <Card className={classes.channelCard}>
              <CardMedia component='img'
                         className={classes.thumbnail}
                         image={savedVideosIcon}
                         title='Saved videos'
                         alt='Saved videos'
              />

              <CardContent className={classes.content}>
                <Typography variant="h3" noWrap>Saved videos</Typography>
              </CardContent>
              <ArrowIcon />
            </Card>
          </CardActionArea>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CardActionArea onClick={navigateToMyPlace}>
            <Card className={classes.channelCard}>
              <CardMedia component='img'
                         className={classes.thumbnail}
                         image={myPlaceIcon}
                         title='My Place'
                         alt='My Place'
              />

              <CardContent className={classes.content}>
                <Typography variant="h3" noWrap>My Place</Typography>
              </CardContent>

              <ArrowIcon />
            </Card>
          </CardActionArea>
        </Grid>

        {channels.map((channel, i) => <ChannelCard key={i} channel={channel} />)}
      </Grid>

      <Disclaimer />

    </React.Fragment>
  );
};
