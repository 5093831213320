import {Button, Card, CardActions, CardContent, CardMedia, Typography} from '@material-ui/core';
import MyPlaceIcon from './assets/AusMapNavIcon.svg';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React, {SyntheticEvent} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      margin: '0 auto',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
    cardMedia: {
      width: 180,
      height: 180,
      borderRadius: 12,
      margin: '0 auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    ausIcon: {
      width: 250,
      height: 250,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    cardContent: {
      fontSize: '1.2em',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    enableButton: {
      margin: '0 auto',
      backgroundColor: '#8ec53f',
      borderRadius: 40,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      color: 'white',
      '&:hover': {
        backgroundColor: 'black',
      },
      '&.Mui-disabled': {
        backgroundColor: '#e0e0e0!important',
      }
    },
  };
});

interface PrimerCardProps {
  onClickHandler?: (event: SyntheticEvent) => void;
  disabledHandler: boolean;
}

export const PrimerCard: React.FunctionComponent<PrimerCardProps> = ({ onClickHandler, disabledHandler }: PrimerCardProps) => {
  const classes = useStyles()
  return(
      <Card className={classes.card} data-testid='enable-location-primer'>
        <Typography gutterBottom variant="h5" component="h2" align="center">
          Allow Location Access
        </Typography>

        <CardMedia component="img"
                   className={[classes.cardMedia, classes.ausIcon].join(" ")}
                   image={MyPlaceIcon}
                   title="My Place"
                   alt="My Place"
        />

        <CardContent>
          <Typography variant="body2" color="textPrimary" component="p" align="center"
                      className={classes.cardContent}>
            If you&apos;d like to see videos and content for your local region, we need
            permission to use your phone&apos;s location service.
          </Typography>
        </CardContent>

        <CardActions>
          <Button size="small"
                  color="primary"
                  className={classes.enableButton}
                  endIcon={<LocationOnIcon />}
                  onClick={onClickHandler}
                  disabled={disabledHandler}
                  data-testid='enable-location-button'
          >
            Enable Location
          </Button>
        </CardActions>
      </Card>
  )
}