import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { RefObject, useEffect } from "react";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
  }
});

interface VideoPlayerProps {
  sources: string[];
  forwardedRef?: RefObject<HTMLVideoElement>;
}

export const VideoPlayer = ({ sources, forwardedRef }: VideoPlayerProps): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    if (forwardedRef?.current) {
      forwardedRef.current.load();
    }
  }, [sources, forwardedRef])

  const hasSources = sources.length > 0;
  return (
    <>
      {!hasSources && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Unable to display video.
        </Alert>
      )}

      {hasSources && (
        <Card className={classes.root}>
          <CardMedia component="video"
                     controls
                     controlsList="nodownload"
                     ref={forwardedRef}
                     data-testid='video-player'
                     crossOrigin="anonymous"
          >
            {sources.map((source, index) => <source src={source} key={index} data-testid={`source-${index}`} />)}
          </CardMedia>
        </Card>
      )}
    </>
  );
}
