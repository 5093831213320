import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useLocation } from "react-router-dom";

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
}

export const Debug: React.FunctionComponent = () => {
  const query = useQuery();
  const debug = query.get('debug') === 'true';

  if (debug) {
    return (
      <>
        <br />
        <Typography variant='h5'>Debug</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Browser</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <ul>
              <li>Browser Version: {window.navigator.appVersion}</li>
              <li>CacheStorage API?: {'caches' in window ? 'true' : 'false'}</li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }

  return <React.Fragment />;
}
