import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        backgroundColor: 'white',
        color: '#ED1C24',
        textTransform: 'none',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        fontSize: '1rem',
        borderRadius: 100,
    },
}));

interface DeleteButtonProps {
    onClick?: () => void;
}

export const DeleteButton: React.FunctionComponent<DeleteButtonProps> = ({ onClick }: DeleteButtonProps) => {
    const classes = useStyles();

    return (
        <Button variant="contained"
            disableElevation
            data-testid="delete-button"
            endIcon={<DeleteIcon />}
            onClick={onClick}
            className={classes.deleteButton}
        >
            Delete Video
        </Button>
    );
};