import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Channel, ContentItem } from './ChannelRepository';
import { ContentItemCard } from './ContentItemCard';

interface ChannelGridProps {
  index: number;
  url: string;
  channel: Channel;
  parent?: Channel | undefined;
  item: ContentItem;
}

// TODO: Test
export const ChannelGrid: React.FunctionComponent<ChannelGridProps> = ({ index, url, channel, parent, item }: ChannelGridProps) => {
  const isSmallScreen = useMediaQuery('(max-width:411px)');

  return (
    <Grid item xs={ isSmallScreen ? 6 : 'auto' } key={ index }>
      <ContentItemCard index={ index } item={ item } url={ url } channel={ channel } parent={ parent } />
    </Grid>
  );
};
