import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { ChannelGrid } from "./ChannelGrid";
import { Channel, ContentItem } from "./ChannelRepository";
import HitnetApiClient from './HitnetApiClient';
import { InstructionsCard } from './InstructionsCard';
import { PrimerCard } from './PrimerCard';
import { PageDescription } from './sharedUiElements/PageDescription';
import { Subheading } from './sharedUiElements/Subheading';
import { VideoPage } from "./VideoPage";
import { AnalyticsAPI } from '.';

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      margin: '0 auto',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
    cardMedia: {
      width: 180,
      height: 180,
      borderRadius: 12,
      margin: '0 auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    ausIcon: {
      width: 250,
      height: 250,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    cardContent: {
      fontSize: '1.2em',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    noContentAlert: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: 'white',
      '& .MuiAlert-icon': {
        color: 'white',
      }
    },
    enableButton: {
      margin: '0 auto',
      backgroundColor: '#8ec53f',
      borderRadius: 40,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      color: 'white',
      '&:hover': {
        backgroundColor: 'black',
      },
      '&.Mui-disabled': {
        backgroundColor: '#e0e0e0!important',
      }
    },
  };
});

export const LOCATION_ACCESS_REQUESTED_KEY = 'locationRequestStatus';
export const MyPlacePage : React.FunctionComponent<AnalyticsAPI> = (analyticsAPI : AnalyticsAPI) => {
  const { path, url } = useRouteMatch();
  const location = useLocation();

  const classes = useStyles();

  const [geolocationButtonDisabled, setGeolocationButtonDisabled] = useState<boolean>(false);

  const [pageTitle, setPageTitle] = useState<string>('My Place');
  const [showContent, setShowContent] = useState<boolean>(false);

  const [myPlaceChannel, setMyPlaceChannel] = useState<Channel | null>(null);

  const [locationRequestStatus, setLocationRequestStatus] = useState<string | null>(
    window.localStorage.getItem(LOCATION_ACCESS_REQUESTED_KEY)
  );

  const onLocationSuccess = (positionOverride: GeolocationPosition | null) => (devicePosition: GeolocationPosition): void => {
    setLocationRequestStatus('Allowed');
    window.localStorage.setItem(LOCATION_ACCESS_REQUESTED_KEY, 'Allowed');

    const url = `${ process.env.REACT_APP_HITNET_API_URL }`;
    const apiClient = new HitnetApiClient(url);

    const position = positionOverride || devicePosition;
    apiClient.getMyPlace(position)
      .then(channel => {
        setPageTitle(channel?.title || 'My Place');
        setMyPlaceChannel(channel);
      })
      .finally(() => {
        setShowContent(true);
      });
  };

  const onLocationError = (): void => {
    setLocationRequestStatus('Denied');
    window.localStorage.setItem(LOCATION_ACCESS_REQUESTED_KEY, 'Denied');
  };

  useEffect(() => {
    if (locationRequestStatus !== null) {
      if ('geolocation' in navigator) {
        let positionOverride: GeolocationPosition | null = null;

        // TODO: Hacky ... Need a better way to do this [JPM]
        if (window.location.hostname !== 'hitnet.app') {
          const query = new URLSearchParams(location.search);
          if (query.has('latitude') && query.has('longitude')) {
            positionOverride = {
              coords: {
                latitude: Number(query.get('latitude')),
                longitude: Number(query.get('longitude'))
              } as GeolocationCoordinates
            } as GeolocationPosition;
          }
        }

        if (locationRequestStatus !== 'Denied') {
          navigator.geolocation.getCurrentPosition(onLocationSuccess(positionOverride), onLocationError);
        }
      }
    }
  }, [location, locationRequestStatus]);

  const enableGeolocationClickHandler = (): void => {
    setGeolocationButtonDisabled(true);

    if (window.localStorage) {
      window.localStorage.setItem(LOCATION_ACCESS_REQUESTED_KEY, 'Requested');
      setLocationRequestStatus('Requested');
    }
  };

  return (
    <React.Fragment>
      <Subheading>{ pageTitle }</Subheading>
      <PageDescription>Videos from your local community.</PageDescription>

      { locationRequestStatus === null && (
        <PrimerCard onClickHandler={ enableGeolocationClickHandler } disabledHandler={ geolocationButtonDisabled } />
      ) }

      { locationRequestStatus === 'Denied' && (
        <InstructionsCard />
      ) }

      { showContent && myPlaceChannel === null && (
        <Alert severity="info" className={ classes.noContentAlert }>
          There are no videos for your region at this time. Check again later.
        </Alert>
      ) }

      { showContent && myPlaceChannel && (
        <Switch>
          <Route exact path={ path }>
            <Grid container spacing={ 2 }>
              { myPlaceChannel.contentItems.map((item: ContentItem, index: number): JSX.Element => (
                <ChannelGrid index={ index } item={ item } url={ url } channel={ myPlaceChannel } key={ index } />
              )) }
            </Grid>
          </Route>

          <Route path={ `${ path }/videos/:videoId` }>
            <VideoPage channelOverride={ myPlaceChannel } analyticsAPI={analyticsAPI}/>
          </Route>
        </Switch>
      ) }
    </React.Fragment>
  );
};
