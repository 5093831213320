import Grid from "@material-ui/core/Grid";
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from "react";
import { Channel, ContentItem, ChannelRepository, defaultChannelRepository, VideoItem } from "./ChannelRepository";
import { PageDescription } from "./sharedUiElements/PageDescription";
import { Subheading } from "./sharedUiElements/Subheading";
import {makeStyles} from "@material-ui/core/styles";
import { ChannelGrid } from "./ChannelGrid"

interface SavedVideoPageProps {
  repository?: ChannelRepository;
}

interface CachedVideoInfo {
  item: ContentItem;
  channel: Channel;
}

const useStyles = makeStyles(() => {
  return {
    noSavedVideosAlert: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      color: "white",
      "& .MuiAlert-icon": {
        color: "white",
      }
    },
  };
});

export const SavedVideoPage = ({ repository = defaultChannelRepository }: SavedVideoPageProps): JSX.Element => {
  const classes = useStyles();
  const [hasEmptyCache, setEmptyCache] = useState<boolean>();
  const [cachedItems, setCachedItems] = useState<CachedVideoInfo[]>([]);

  useEffect(() => {
    window.caches.open('videos').then((cache) => {
      cache.keys().then(requests => {
        setEmptyCache(requests.length === 0);

        const items = requests.map(request => {
          const regex = /https:\/\/content\.hitnet\.app\/([^/]*)\/assets\/videos\/([^/]*)$/;
          const matches = request.url.match(regex);
          if (matches) {
            const channelTitle = decodeURIComponent(matches[1]);
            const videoName = matches[2];

            const channel = repository.getChannelByTitle(channelTitle);
            const item = channel?.contentItems.find(item => {
              const videoItem = item as VideoItem;
              return videoItem.videos && videoItem.videos.includes(videoName);
            });

            if (channel && item) {
              return { item, channel };
            }
          }

          return undefined;
        });

        const filtered = items.filter(item => !!item) as CachedVideoInfo[];
        setCachedItems(filtered);
      });
    })
  }, [repository])

  return (
    <React.Fragment>
      <Subheading>Saved videos</Subheading>
      <PageDescription>All the videos saved on this device</PageDescription>

      {hasEmptyCache && (
        <Alert severity="info" className={classes.noSavedVideosAlert}>
          You don&apos;t have any videos saved.
        </Alert>
      )}

      <Grid container spacing={2}>
        {cachedItems.map((itemInfo: CachedVideoInfo, index: number): JSX.Element => (
          <ChannelGrid
             index={index}
              item={itemInfo.item}
              url={`/channels/${itemInfo.channel.id}`}
              channel={itemInfo.channel}
              key={index}
            />

        ))}
      </Grid>
    </React.Fragment>
  )
}
