import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

interface SubheadingProps {
  children?: React.ReactNode;
}

export const Subheading: React.FunctionComponent<SubheadingProps> = (({ children }: SubheadingProps) => {
  const classes = makeStyles(() => ({
    root: {
      color: 'white',
      fontSize: '1.3rem',
      fontFamily: 'Poppins',
      fontWeight: 700,
      margin: "24px 0px",

    }
  }))();

  return (
    <Typography variant='h2' className={classes.root}>
      {children}
    </Typography>
  )
});