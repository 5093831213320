import { Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    fontWeight: 300,
    color: 'white',
    textAlign: 'center',
    margin: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
}));

export const WelcomeMessage: React.FunctionComponent = () => {
  const classes = useStyles();

  return (

    <Grid container justify='center'>
      <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
        <Typography variant="h6" className={classes.message}>
          Keep up to date with the latest information for you and your community
        </Typography>
      </Grid>
    </Grid>
  );
}
