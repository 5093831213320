import { ChannelManifest } from "./ChannelManifest";

export interface VideoItem {
  id: string;
  title: string;
  image: string;
  description?: string;
  videos: string[];
}

export interface Channel {
  id: string;
  title: string;
  path?: string; // server path for the channel. Use this to override 'title' when path is different to channel title.
  image: string;
  contentItems: ContentItem[]; // TODO: Can we prohibit empty array with TypeScript? [JPM]
}

export type ContentItem = Channel | VideoItem


// TODO: Use getters here? [JPM]
export class ChannelRepository {
  private readonly _channels: Channel[];

  constructor(content: Channel[] = []) {
    this._channels = content;
  }

  getChannels(): Channel[] {
    return this._channels;
  }

  getChannel(id: string): Channel | undefined {
    let result: Channel | undefined;

    this._channels.some(channel => {
      if (channel.id === id) {
        result = channel;
      } else {
        // NOTE: Assumes only one nested subchannel
        result = channel.contentItems
          .filter((item: ContentItem): item is Channel => (item as Channel).contentItems !== undefined)
          .find((channel) => channel.id === id)
      }

      if (result) {
        return true
      }
    })

    return result;
  }

  getChannelByTitle(title: string): Channel | undefined {
    return this._channels.find((channel) => {
      return channel.title === title
    })
  }
}

export const defaultChannelRepository = new ChannelRepository(ChannelManifest);
