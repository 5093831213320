import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useLocation, Route, Switch } from 'react-router';
import { ChannelPage } from './ChannelPage';
import { HomePage } from './HomePage';
import { MyPlacePage } from './MyPlacePage';
import { SavedVideoPage } from './SavedVideoPage';
import { AnalyticsAPI } from '.';


const useStyles = makeStyles(() =>
  createStyles({
    noMatch: {
      display: 'flex',
      justifyContent: 'center',
      color: '#FFFFFF',
    },
  })
);

const NoMatch: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.noMatch}>
      Unfortunately, we weren&apos;t able to find that page for you ;-(
    </Typography>
  );
};


export const Routes = (analyticsAPI : AnalyticsAPI): JSX.Element => {
  const location = useLocation();

  // Manually triggers service worker updates when SPA route changes
  // Mimics the behaviour of updates in a server routed application
  // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#updates
  // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) =>
        registration.update()
      );
    }
    if (window.hitnet?.gaToken){
      analyticsAPI.pageview(window.location.pathname);
    }

  }, [location]);

  // There should be only *one* ToastContainer in the react tree
  return (
    <>
      <ToastContainer position="top-center" autoClose={false} closeOnClick />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/saved-videos">
          <SavedVideoPage />
        </Route>
        <Route path="/my-place">
          <MyPlacePage {...analyticsAPI}/>
        </Route>
        <Route path="/channels/:channelId">
          <ChannelPage analyticsAPI={analyticsAPI}/>
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
};
