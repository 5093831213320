import React, { useEffect } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { Debug } from './Debug';
import { Header } from './Header';
import { Routes } from './Routes';
import { AnalyticsAPI } from '.';

const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: 'Poppins',
    },
    h2: {
      fontFamily: 'Poppins',
    },
    h3: {
      fontSize: '1rem',
      fontFamily: 'Poppins',
      fontWeight: 500,
      lineSpacing: 20,
    },
    h4: {
      fontFamily: 'Poppins',
    },
    h5: {
      fontSize: '1.3rem',
      fontFamily: 'Poppins',
      fontWeight: 700,
      lineSpacing: 31,
    },
    h6: {
      fontSize: '1rem',
      fontFamily: 'Poppins',
      fontWeight: 500,
      lineSpacing: 20,
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '1.25em',
    },
    caption: {
      color: '#1f1f1f',
      fontWeight: 500,
      fontSize: '1em',
      lineHeight: 1,
    },
  },
});

declare global {
  interface Window {
    hitnet: { gaToken: string | undefined };
  }
}


// NOTE: Order of routes is most specific first [JPM]
export const App = (analyticsAPI:AnalyticsAPI): JSX.Element => {
  useEffect(()=>{
    if (window.hitnet?.gaToken){
      analyticsAPI.initialize(window.hitnet.gaToken);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Header />

        <Container>
          <Routes {...analyticsAPI}/>

          <Debug />
        </Container>
      </Router>
    </ThemeProvider>
  );
};
