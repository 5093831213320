import { Icon, makeStyles, Theme } from "@material-ui/core";
import { NavigateNextRounded } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme: Theme) => {
  return {
    next: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 100,
      border: '1px solid',
      color: theme.palette.grey[300],
      borderColor: theme.palette.grey[200],
      padding: 14,
    }
  }
});

export const ArrowIcon: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Icon className={classes.next}>
      <NavigateNextRounded />
    </Icon>
  )
};