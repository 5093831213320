import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { ContentItem, ChannelRepository, defaultChannelRepository, Channel } from './ChannelRepository';
import { Subheading } from './sharedUiElements/Subheading';
import { VideoPage } from "./VideoPage";
import { ChannelGrid } from "./ChannelGrid"
import { AnalyticsAPI } from ".";

interface ChannelPageParams {
  channelId: string;
  subChannelId: string;
}

interface ChannelPageProps {
  repository?: ChannelRepository;
  analyticsAPI: AnalyticsAPI;
}

export const ChannelPage: React.FC<ChannelPageProps> = ({ repository = defaultChannelRepository, analyticsAPI: analyticsAPI }: ChannelPageProps) => {
  const { channelId, subChannelId } = useParams<ChannelPageParams>();
  const { path, url } = useRouteMatch();

  let channel: Channel | undefined;
  let parentChannel: Channel | undefined;

  if (subChannelId) {
    channel = repository.getChannel(subChannelId);
    parentChannel = repository.getChannel(channelId);
  } else {
    channel = repository.getChannel(channelId);
  }

  if (channel === undefined) {
    return (
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        Unable to locate the specified channel.
      </Alert>
    )
  }

  if (channel.contentItems.length === 0) {
    return (
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        Unfortunately this channel appears to be empty.
      </Alert>
    )
  }

  return (
    <Switch>
      <Route exact path={ url }>
        <Subheading>{ channel.title }</Subheading>

        <Grid container spacing={ 2 }>
          {
            channel.contentItems.map((item: ContentItem, index: number): JSX.Element => (
              <ChannelGrid index={ index } item={ item } url={ url } channel={ channel as Channel } parent={ parentChannel } key={ index } />
            ))
          }
        </Grid>
      </Route>

      <Route path={ `${ path }/videos/:videoId` }>
        <VideoPage repository={ repository } analyticsAPI={analyticsAPI} />
      </Route>

      <Route path={ `${ path }/:subChannelId` }>
        <ChannelPage repository={ repository } analyticsAPI={analyticsAPI} />
      </Route>
    </Switch>
  );
}
