import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteButton } from './DeleteButton'
import { Channel, ContentItem, ChannelRepository, defaultChannelRepository, VideoItem } from './ChannelRepository';
import { VideoPlayer } from './VideoPlayer';
import { SaveButton } from "./SaveButton";
import { AnalyticsAPI } from '.';

const config = {
  content: {
    hostname: 'content.hitnet.app',
    paths: {
      assets: 'assets',
      images: 'assets/images',
      videos: 'assets/videos',
    }
  },
  videoCacheName: 'videos'
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  channelTitle: {
    color: 'white',
  },
  contentItemTitle: {
    color: 'white',
    fontSize: '1.5rem',
    marginBottom: theme.spacing(4),
  },
  contentItemDescription: {
    marginBottom: theme.spacing(4),
    color: 'white',
  },
  offlineWarning: {
    marginTop: theme.spacing(3),
  },
}));

interface VideoSources {
  channel: Channel;
  parent?: Channel;
  item: ContentItem;
  cachedVideoCallback?: () => void;
}

const getVideoSources = async ({ channel, parent, item, cachedVideoCallback }: VideoSources): Promise<string[]> => {
  const source = parent ? parent : channel;
  const encodedChannelTitle = encodeURIComponent(source.path ? source.path : source.title);
  const baseVideoUrl = `https://${ config.content.hostname }/${ encodedChannelTitle }/${ config.content.paths.videos }`;

  const sourceUrls = (item as VideoItem).videos.map((source: string) => `${ baseVideoUrl }/${ source }`);

  let result: Promise<string[]> = Promise.resolve(sourceUrls);
  if ('caches' in window) {
    // Check whether item has been cached...
    const cache = await window.caches.open('videos');
    const cachedResponses = await Promise.all(sourceUrls.map(url => cache.match(url)));

    // NOTE: Only use first cached response as there should never be more
    const cachedResponse = cachedResponses.find(match => !!match);
    if (cachedResponse) {
      if (cachedVideoCallback) {
        cachedVideoCallback();
      }
      const blob = await cachedResponse.blob();
      result = Promise.resolve([URL.createObjectURL(blob)]);
    }
  }

  return result;
};

interface VideoPageParams {
  channelId: string;
  subChannelId?: string;
  videoId: string;
}

interface VideoPageProps {
  channelOverride?: Channel;
  repository?: ChannelRepository;
  analyticsAPI: AnalyticsAPI;
}

export const VideoPage: React.FC<VideoPageProps> = ({ repository = defaultChannelRepository, channelOverride, analyticsAPI: analyticsAPI }: VideoPageProps) => {
  const classes = useStyles();

  const { channelId, subChannelId, videoId } = useParams<VideoPageParams>();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isOffline, setIsOffline] = useState<boolean>(false);

  const [channel, setChannel] = useState<Channel>();
  const [contentItem, setContentItem] = useState<ContentItem>();
  const [sources, setSources] = useState<string[]>([]);

  const [buttonText, setButtonText] = useState<string>('Save Video');
  const [videoIsCached, setVideoIsCached] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const videoPlayerRef = useRef<HTMLVideoElement>(null);

  const videoSaved = (): void => {
    setVideoIsCached(true);
    setIsSaving(false);
  };

  const videoDeleted = (): void => {
    setVideoIsCached(false);
    setIsSaving(false);
    setButtonText('Save Video');
  };

  const savingInProgress = (): void => {
    setIsSaving(true);
    setButtonText('Saving...');
  };

  useEffect(() => {
    setIsOffline(!window.navigator.onLine);
  }, []);

  useEffect(() => {
    let channel;
    let parentChannel;

    if (subChannelId) {
      channel = repository.getChannel(subChannelId);
      parentChannel = repository.getChannel(channelId);
    } else {
      channel = channelOverride || repository.getChannel(channelId);
    }

    if (channel) {
      setChannel(channel);
      const contentItem = channel.contentItems.find(item => item.id === videoId);
      if (contentItem) {
        setContentItem(contentItem);
        getVideoSources({
          channel: channel,
          parent: parentChannel,
          item: contentItem,
          cachedVideoCallback: videoSaved
        }).then(sources => {
          setSources(sources);
          setIsLoaded(true);
        }).catch(console.error);
      } else {
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
  }, [channelOverride, repository, channelId, videoId]);


  // NOTE: The following will save the video that was selected as the most appropriate by the browser [JPM]
  const saveVideoHandler = (event: SyntheticEvent): void => {
    event.preventDefault();
    savingInProgress();

    // Save browser selected video to cache
    let chosenVideoResourceUrl: string;
    if (videoPlayerRef?.current) {
      const videoPlayer = videoPlayerRef.current as HTMLVideoElement;
      chosenVideoResourceUrl = videoPlayer.currentSrc;
      if (chosenVideoResourceUrl.length === 0) {
        chosenVideoResourceUrl = (videoPlayer.children[0] as HTMLSourceElement).src;
      }

      const request = new Request(chosenVideoResourceUrl, {
        // NOTE: Required to avoid "Cache got cors response with bad status 206" on Firefox.
        headers: { 'Content-Range': 'bytes 0-' }
      })
      window.caches.open(config.videoCacheName)
        .then(cache => cache.add(request))
        .then(() => {
          videoSaved();
        })
        .catch(console.error);
    }
  };

  const deleteVideoHandler = async (): Promise<void> => {
    if (channel) {
      const baseVideoUrl = `https://${ config.content.hostname }/${ encodeURIComponent(channel.title) }/${ config.content.paths.videos }`;
      const contentItem = channel.contentItems.find(item => item.id === videoId);
      const sourceUrls = (contentItem as VideoItem)?.videos.map((source: string) => `${ baseVideoUrl }/${ source }`);

      if (sourceUrls && sourceUrls.length > 0) {
        const cache = await window.caches.open('videos');
        const cachedVideos = await Promise.all(sourceUrls.map(url => cache.match(url)));

        if (cachedVideos.length > 0) {
          const result = await Promise.all(sourceUrls.map(url => cache.delete(url)));
          setSources(sourceUrls);
          if (result.includes(true)) {
            videoDeleted();
          }
        }
      }
    }
  };

  const sendSaveAnalytics = () : void => {
    analyticsAPI.event({category: "Button Click", action: "Save video", label: "Saved video with id:" + videoId});
  };

  const isOnline = !isOffline;
  return (
    <>
      { isLoaded && !channel && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Unable to locate the specified channel.
        </Alert>
      ) }

      { isLoaded && channel && !contentItem && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Unable to locate the specified video.
        </Alert>
      ) }

      { isOffline && !videoIsCached && (
        <Alert severity="warning" className={ classes.offlineWarning }>
          <AlertTitle>Warning</AlertTitle>
          Connect to Wi-Fi Hotspot or Mobile Data to continue watching videos.
        </Alert>
      ) }

      { isOffline && videoIsCached && (
        <Grid container spacing={ 2 } className={ classes.root }>
          <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } xl={ 8 }>
            <VideoPlayer forwardedRef={ videoPlayerRef } sources={ sources } />
          </Grid>

          <Grid item>
            <Typography variant='h6' className={ classes.channelTitle }>{ channel?.title }</Typography>
            <Typography variant='h5' className={ classes.contentItemTitle }>{ contentItem?.title }</Typography>

            <DeleteButton onClick={ deleteVideoHandler } />
          </Grid>
        </Grid>
      ) }

      { isOnline && isLoaded && channel && contentItem && (
        <Grid container spacing={ 2 } className={ classes.root }>
          <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } xl={ 8 }>
            <VideoPlayer forwardedRef={ videoPlayerRef } sources={ sources } />
          </Grid>

          <Grid item>
            <Typography variant='h6' className={ classes.channelTitle }>{ channel?.title }</Typography>
            <Typography variant='h5' className={ classes.contentItemTitle }>{ contentItem?.title }</Typography>
            { (contentItem as VideoItem)?.description && (
              <Typography variant='body1' className={ classes.contentItemDescription }>{ (contentItem as VideoItem)?.description }</Typography>
            ) }
            { !videoIsCached && (
              <SaveButton onClick={function(event){ saveVideoHandler(event); sendSaveAnalytics()}} disabled={ videoIsCached || isSaving } buttonText={ buttonText } />
            ) }

            { videoIsCached && (
              <DeleteButton onClick={ deleteVideoHandler } />
            ) }
          </Grid>
        </Grid>
      ) }
    </>
  );
};
