import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { render } from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { App } from './App';
import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import ReactGA from 'react-ga';
export interface AnalyticsAPI {
  initialize: (key: string) => void;
  pageview: (page: string) => void;
  event: (args: {category: string, action: string, label: string}) => void;
}
export const analyticsAPI = ReactGA as AnalyticsAPI;

render(<App {...analyticsAPI}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change unregister() to register() below. Note this
// comes with some pitfalls. Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  // On finding an updated service worker, proactively display a toast
  // allowing the users to install immediately instead of waiting until
  // they close and reopen the application
  onUpdate: async function (registration) {
    toast(
      <Typography align="center" variant="body1">
        There is a new version available
        <Button>
          <Typography variant="body1">Refresh</Typography>
        </Button>
      </Typography>,
      {
        onClick: async () => {
          if (registration && registration.waiting) {
            await registration.unregister();
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // Once the service worker is unregistered, we can reload the page to let
            // the browser download a fresh copy of our app (invalidating the cache)
            window.location.reload();
          }
        },
      }
    );
  },
});
